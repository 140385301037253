// Import only the Bootstrap components we need
// https://stackoverflow.com/questions/64113404/bootstrap-5-referenceerror-bootstrap-is-not-defined
import * as bootstrap from 'bootstrap'
//window.bootstrap = bootstrap;

/* * * * * * * * * * * * * * * * * * *
 * Bootstrap Carousel Fullscreen
 * https://codepen.io/SitePoint/pen/ZbGwqe
 *
 * #2 : https://codepen.io/kasilagml/pen/eWPyjL
 * * * * * * * * * * * * * * * * * * */

const myCarouselElement = document.querySelector('#PHPCarousel')

if (document.querySelector('#PHPCarousel')) {
    const carousel = new bootstrap.Carousel(myCarouselElement, {
        interval: 4000,
        ride: true,
        pause: 'hover'
    });
}

/* * * * * * * * * * * * * * * * * * *
 * back-to-top
 * * * * * * * * * * * * * * * * * * */

let mybutton = document.getElementById("btn-back-to-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
    scrollFunction();
};

function scrollFunction() {
    if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
    ) {
        mybutton.style.display = "block";
    } else {
        mybutton.style.display = "none";
    }
}
// When the user clicks on the button, scroll to the top of the document
mybutton.addEventListener("click", backToTop);

function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}
